import React from "react";
import { Button, Input } from 'antd';
import Banner from './Banner'; //
import { RegName , RegEmail , RegPassword , RegPhone, RegNameNumber, RegNameLetter } from '$CONFIG/common/reg';
import { ApiPort } from "../../actions/API";
import { fetchRequest } from "../../config/Fatch.config";
import { Cookie , getBrowserVersion, _handleTheMainSiteUrlOfBackendResponse, getApiVersion } from "../../actions/util";

import Toast from '@/Toast';

class Central extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			contact : [{
				icon: '',
				text: 'Live Chat',
				href: '',
			},{
				icon: '',
				text: 'Line',
				href: '',
			},{
				icon: '',
				text: 'Facebook',
				href: '',
			},{
				icon: '',
				text: 'Youtube',
				href: '',
			}],
			error_name : false ,
			error_pwd  : false,
			error_email:false,
			error_phone:false,

			EMAIL : '',
			USERNAME : '',
			PHONE : '',
			PWD : '',
			currentUsername: '',
			currentPwd: '',
			currentEmail: '',
			currentPhone: ''
		}
		this.Register = this.Register.bind(this);
		this.emailBox = React.createRef();
		this.phoneBox = React.createRef();
		this.usernameBox = React.createRef();
		this.passwordBox = React.createRef();

	}

	componentDidMount(){
	}

	handleEmailClick = (event) => {
		if (this.emailBox && this.emailBox.current && !this.emailBox.current.contains(event.target) && !this.state.error_email && (this.state.currentEmail != this.state.EMAIL)) {
			this.setState({
				currentEmail :  this.state.EMAIL,
			});
			global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Email_input' ,'input');
			return document.removeEventListener('click', this.handleEmailClick)
		}
	}

	handlePhoneClick = (event) => {
		if (this.phoneBox && this.phoneBox.current && !this.phoneBox.current.contains(event.target) && !this.state.error_phone && (this.state.currentPhone != this.state.PHONE)) {
			this.setState({
				currentPhone :  this.state.PHONE,
			});
			global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Phone_input' ,'input');
			return document.removeEventListener('click', this.handlePhoneClick)
		}
	}

	handleUsernameClick = (event) => {
		if (this.usernameBox && this.usernameBox.current && !this.usernameBox.current.contains(event.target) && !this.state.error_name && (this.state.currentUsername != this.state.USERNAME)) {
			this.setState({
				currentUsername :  this.state.USERNAME,
			});
			global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Username_input' ,'input');
			return document.removeEventListener('click', this.handleUsernameClick)
		}
	}

	handlePasswordClick = (event) => {
		if (this.passwordBox && this.passwordBox.current && !this.passwordBox.current.contains(event.target) && !this.state.error_pwd && (this.state.currentPwd != this.state.PWD)) {
			this.setState({
				currentPwd :  this.state.PWD,
			});
			global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Password_input' ,'input');
			return document.removeEventListener('click', this.handlePasswordClick)
		}
	}

	/**
	 * 初始化方法
	 */
	init(){

	}

	/**
	 * 改变参数值
	 * @param { String } k 变量
	 * @param { String | Number | any } v 参数
	 */
	setStates(k, v) {
		this.setState({
		  [k]: v
		});
	};


	/**
	 * onChange input
	 * @param {*} e
	 * @param {*} type
	 * @returns
	 */
	UserInput(e , type){
		let val = e.target.value || '';
		this.setStates(type,val);
		this.regInput(e , type);
		return ;
		// globalGtag(`Search_withdrawalrecord_Carddetail`);

	}

	/**
	 * 注册
	 */
	Register(){
		let { PHONE  , EMAIL , PWD ,USERNAME} = this.state;
		let MAIN_SITE_URL ;
		let IS_IN_IFRAME =
			window.self !== window.top ||
			RegExp("(#|,)iniframe=1").test(location.href); // our url design: http....#aaa=1,bbb=2,ccc=3

		if(PHONE === '' && EMAIL === '' && PWD === '' && USERNAME === '') {
			this.setState({
				error_phone : true,
				error_email : true,
				error_name  : true,
				error_pwd   : true,
			})
			return;
		}
		if(!this.state.error_phone && !this.state.error_email && !this.state.error_name && !this.state.error_pwd){
			try {
				let params = {
					Referrer : Cookie.GetCookieKeyValue('Referer') || global.location.origin,
					currentDomain : global.location.origin,
					email: EMAIL,
					hidBlackbox : (global.ioGetBlackbox && global.ioGetBlackbox().blackbox) || '',
					hidE2:  (global.E2GetBlackbox && global.E2GetBlackbox().blackbox) || '',
					language: 'th-th',
					media:Cookie.GetCookieKeyValue('Media') || null,
					password : PWD,
					phoneNumber : PHONE,
					affiliateCode : Cookie.GetCookieKeyValue('CO_affiliate') || '' ,
					referralCode : Cookie.GetCookieKeyValue('CO_affiliate') || '',
					regWebsite : '1',
					username : USERNAME ,
					websiteId : getBrowserVersion(),
				}

				// piwik 追踪
				global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Submit_registration' ,'event');

				Toast.loading()
				fetchRequest(ApiPort.Register,'POST' , params , getApiVersion()).then(res =>{
					if(res){
						Toast.hide();
						if(res.isSuccess){
							global.globalCTag && global.globalCTag('api_return_complete','success');
							global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Submit_registration_api_return' ,'event', 'custom');
							_handleTheMainSiteUrlOfBackendResponse(`${res.url}&token=${res.token}`)
						}else{
							global.globalCTag && global.globalCTag('api_return_complete','fail');
							global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Submit_registration_api_return' ,'event', 'custom');
							this.props.openModal(res.message);
						}
					}
				}).catch(error =>{
					console.log('服务器错误...');
				})
			} catch (error) {

			}
		}else{
			return ;
		}

	}

	/**
	 * input Blur
	 * @param {*} e
	 * @param {*} type
	 */
	inputOnBlur(e,type){
		this.regInput(e , type);
	}

	/**
	 * 验证Input 方法
	 * @param {*} e
	 * @param {*} type
	 * @returns
	 */
	regInput(e ,type){
		let val = e.target.value || '';
		let that = this;
		let inputMap = {
			'USERNAME' : (val)=>{
				document.addEventListener('click', this.handleUsernameClick);
				if(!RegName.test(val)){
					that.setState({
						error_name : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('name-hit-error')[0].innerHTML = 'กรุณาระบุยูสเซอร์เนม';

						}else{
							document.getElementsByClassName('name-hit-error')[0].innerHTML = 'โปรดป้อน 6-14 ตัวอักษร (ตัวอักษรและตัวเลขเท่านั้น)';
						}

					});
				} else{
					that.setState({
						error_name :  false
					});
				}
				return ;
			},
			'PWD' : (val) =>{
				document.addEventListener('click', this.handlePasswordClick);
				if(!RegPassword.test(val)){
					that.setState({
						error_pwd : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = 'กรุณาระบุรหัสผ่าน';
						}else{
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = 'รหัสผ่านต้องมีความยาว 6-20 ตัวอักษรและตัวอักษรต้องไม่เกินตัวอักษรและตัวเลข';
						}
					})

				}else{
					that.setState({
						error_pwd :  false
					})
				}
				return ;
			},
			'EMAIL' : (val) =>{
				document.addEventListener('click', this.handleEmailClick);
				if(!RegEmail.test(val)){
					that.setState({
						error_email : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('email-hit-error')[0].innerHTML = 'กรุณาระบุอีเมล์';
						}else{
							document.getElementsByClassName('email-hit-error')[0].innerHTML = 'รูปแบบอีเมลไม่ถูกต้อง';
						}
					})
				}else{
					that.setState({
						error_email :  false
					})
				}
				return;

			},
			'PHONE' : (val) =>{
				document.addEventListener('click', this.handlePhoneClick);
				if(!RegPhone.test(val)){
					that.setState({
						error_phone : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = 'กรุณาระบุเบอร์โทรศัพท์';
						}else{
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = 'หมายเลขโทรศัพท์ไม่ควรลงทะเบียนหรือเป็นของ บริษัท ใด ๆ';
						}
					})
				}else{
					that.setState({
						error_phone :  false
					})
				}
				return
			}
		}
		inputMap[type](val);
		return ;
	}

	render(){
		let {error_name ,error_pwd , error_email , error_phone , PWD ,USERNAME , EMAIL , PHONE  } = this.state;
		return (
			<>
				<Banner bannerList = { this.props.bannerList }></Banner>
				<div className="central-web web">
					{/*<div className='text-top'>
						<h2>เว็บบอลอันดับ 1 มั่นคง ปลอดภัย </h2>
						<p>โบนัสสูง 150% ฟรีเสื้อบอลโลก</p>
					</div>*/}
					<div className="central-box web">
						<div className={'heading'}>สมัครสมาชิก</div>
						<p className={'tips'}>ฝากครั้งแรก สุดคุ้ม !</p>
						<div className={'form-modal'}>
							<div className="namePass">
								{/* 用户名 */}
								<div style={{width: '49%'}} ref={this.usernameBox} className="usernameBox">
									<Input style={{width: '100%'}} onBlur={(e)=>{this.inputOnBlur(e ,'USERNAME')}} type={'name'} onChange={(e)=>{this.UserInput(e ,'USERNAME')}} maxLength={14} placeholder="ชื่อผู้ใช้" value={USERNAME}></Input>
								</div>
								{/* 密码 */}
								<div style={{width: '49%'}} ref={this.passwordBox} className="passwordBox">
									<Input style={{width: '100%'}} type={'password'} onBlur={(e)=>{this.inputOnBlur(e ,'PWD')}} onChange={(e)=>{this.UserInput(e ,'PWD')}} maxLength={20} name="password" placeholder="รหัสผ่าน" value={PWD}></Input>
								</div>
							</div>
							<div className="error-hit">
								{/* 用户名错误显示 */}
								{error_name ? <p className="name-hit-error">กรุณาระบุยูสเซอร์เนม</p> : <p></p>}
								{error_pwd ? <p className="pass-hit-error">กรุณาระบุรหัสผ่าน</p> :<p></p>}
							</div>
							{/*  */}
							{/* 电子邮件 */}
							<div className="email-input" ref={this.emailBox}>
								<Input name="email" onBlur={(e)=>{this.inputOnBlur(e ,'EMAIL')}} maxLength={100} placeholder="อีเมล์" onChange={(e)=>{this.UserInput(e ,'EMAIL')}} value={EMAIL}></Input>
							</div>
							<div className="error-hit">
								{error_email ? <p className="email-hit-error">กรุณาระบุรหัสผ่าน</p> :<p></p>}
							</div>

							{ /* 电话号码 */ }
							<div className="phone-number" ref={this.phoneBox}>
								<Input className="phone" name="phone" disabled type="tel" value={'+66'}></Input>
								<Input className="phoneNumber" onBlur={(e)=>{this.inputOnBlur(e ,'PHONE')}} maxLength={11} name="phoneNumber" id="inputPhone" placeholder="เบอร์โทรศัพท์" onChange={(e)=>{this.UserInput(e ,'PHONE')}} value={PHONE}></Input>
							</div>
							<div className="error-hit">
								{error_phone ? <p className="phone-hit-error">กรุณาระบุรหัสผ่าน</p> :<p></p>}
							</div>
						</div>
						<Button className="submit" onClick={this.Register}>เข้าร่วมเดี๋ยวนี้</Button>
					</div>
				</div>
				{/* mobile and ipad */}
				<div className="mobile">
					{<div className="mobile-central">
						{/*<div className='text-top'>
							<h2>เว็บบอลอันดับ 1 มั่นคง ปลอดภัย </h2>
							<p>โบนัสสูง 150% ฟรีเสื้อบอลโลก</p>
						</div>*/}
					</div>}
					<div className="central-box" id="mobile-heading">
						<div className={'heading'}>สมัครสมาชิก</div>
						<p className={'tips'}>ฝากครั้งแรก สุดคุ้ม !</p>
					</div>
					<div className={'form-modal'}>
						<div className="namePass">
							{/* 用户名 */}
							<div style={{width: '49%'}} ref={this.usernameBox} className="usernameBox">
								<Input style={{width: '100%'}} onBlur={(e)=>{this.inputOnBlur(e ,'USERNAME')}} type={'name'} onChange={(e)=>{this.UserInput(e ,'USERNAME')}} maxLength={14} placeholder="ชื่อผู้ใช้" value={USERNAME}></Input>
							</div>
							{/* 密码 */}
							<div style={{width: '49%'}} ref={this.passwordBox} className="passwordBox">
								<Input style={{width: '100%'}} type={'password'} onBlur={(e)=>{this.inputOnBlur(e ,'PWD')}} onChange={(e)=>{this.UserInput(e ,'PWD')}} maxLength={20} name="password" placeholder="รหัสผ่าน" value={PWD}></Input>
							</div>
						</div>

						<div className="error-hit">
							{/* 用户名错误显示 */}
							{error_name && !USERNAME ? <p className="name-hit-error">กรุณาระบุยูสเซอร์เนม</p> : error_name && USERNAME ? <p>โปรดป้อน 6-14 ตัวอักษร (ตัวอักษรและตัวเลขเท่านั้น)</p> : <p></p>}
							{error_pwd && !PWD ? <p className="pass-hit-error">กรุณาระบุรหัสผ่าน</p> : error_pwd && PWD ? <p>รหัสผ่านต้องมีความยาว 6-20 ตัวอักษรและตัวอักษรต้องไม่เกินตัวอักษรและตัวเลข</p> : <p></p>}
						</div>


						{/* 电子邮件 */}
						<div className="email-input" ref={this.emailBox}>
							<Input onBlur={(e)=>{this.inputOnBlur(e ,'EMAIL')}}  onChange={(e)=>{this.UserInput(e ,'EMAIL')}} value={EMAIL} name="email" maxLength={100} placeholder="อีเมล์"></Input>
						</div>
						<div className="error-hit">
							{error_email && !EMAIL? <p className="email-hit-error">กรุณาระบุอีเมล์</p> : error_email && EMAIL ?<p>รูปแบบอีเมลไม่ถูกต้อง</p> : <p></p>}
						</div>
						{ /* 电话号码 */ }
						<div className="phone-number" ref={this.phoneBox}>
							<Input className="phone" name="phone" disabled type="tel" value={'+66'}></Input>
							<Input className="phoneNumber" maxLength={11} name="phoneNumber" id="inputPhone" placeholder="เบอร์โทรศัพท์" onBlur={(e)=>{this.inputOnBlur(e ,'PHONE')}} onChange={(e)=>{this.UserInput(e ,'PHONE')}} value={PHONE}></Input>
						</div>
						<div className="error-hit">
							{error_phone && !PHONE ? <p className="phone-hit-error">กรุณาระบุเบอร์โทรศัพท์</p> : error_phone && PHONE ? <p>หมายเลขโทรศัพท์ไม่ควรลงทะเบียนหรือเป็นของ บริษัท ใด ๆ</p> : <p></p>}
						</div>
					</div>
					<Button className="submit" onClick={this.Register}>เข้าร่วมเดี๋ยวนี้</Button>
				</div>


			</>
		)
	}
}


export default Central;
